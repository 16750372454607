import './../scss/app.scss';
import './smooth-scolling';
import MobileMenu from "./components/MobileMenu";
import DonateForm from "./components/DonateForm";
import Testimonials from "./components/Testimonials";

(function () {
    new MobileMenu();
    new DonateForm();
    new Testimonials();
})();
