export default class Testimonials {
    constructor() {
        this.currentSlideIndex = 0;
        this.totalSlideIndex = window.testimonials.length - 1;
        this.testimonialSliderElm = document.getElementById('testimonial-slider');

        this.bindEvents();
    }

    bindEvents() {
        if (this.testimonialSliderElm === null) {
            return;
        }

        this.displaySlide();

        document.getElementById('testimonial-prev')
        .addEventListener('click', (e) => {
            e.preventDefault();

            this.currentSlideIndex--;
            this.displaySlide();
          })

    document.getElementById('testimonial-next')
        .addEventListener('click', (e) => {
            e.preventDefault();

            this.currentSlideIndex++;
            this.displaySlide();
          })
    }

    displaySlide() {
        if (this.currentSlideIndex < 0) {
            this.currentSlideIndex = this.totalSlideIndex;
        }
        if (this.currentSlideIndex > this.totalSlideIndex) {
            this.currentSlideIndex = 0;
        }

        const slide = window.testimonials[this.currentSlideIndex] || null;
        if (slide) {
            this.testimonialSliderElm.querySelector('.testimonials__text').innerHTML = `“${slide.content}”`
            this.testimonialSliderElm.querySelector('.testimonials__author').innerHTML = `${slide.author}`
        }
    }
}
