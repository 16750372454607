export default class MobileMenu {
  constructor() {
    this.buttonElm = document.querySelector('.nav__toggler');
    this.navbarLayerElm = document.querySelector('.nav-overlay');

    this.bindEvents();
  }

  bindEvents() {
    this.buttonElm.addEventListener("click", () => {
      this.buttonElm.classList.toggle('nav__toggler--active');
      this.navbarLayerElm.classList.toggle('nav-overlay--active');
    });

    this.navbarLayerElm.addEventListener("click", (e) => {
      if(e.target === this.navbarLayerElm) {
        this.buttonElm.classList.toggle('nav__toggler--active');
        this.navbarLayerElm.classList.toggle('nav-overlay--active');
      }
    });
  }
}
