const fields = [
  'dmm_recurring_interval',
  'dmm_name',
  'dmm_email',
  'dmm_address',
  'dmm_zipcode',
  'dmm_city',
  'dmm_country',
  'dmm_currency',
];

class DonateForm {
  constructor() {
    this.isDonateForm = document.getElementById('dmm_interval') !== null;

    if (this.isDonateForm === true) {
      this.addClassesToParams();
    }
  }

  addClassesToParams() {
    for (const fieldName of fields) {
      const field = document.getElementsByName(fieldName)[0];
      const fieldParam = field.parentNode;

      if (fieldParam) {
        fieldParam.className += 'field-' + fieldName.replace('dmm_', '')
      }
    }
  }
}

export default DonateForm
